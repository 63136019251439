import { useQuery } from "@apollo/client";
import { noneCurrencyThousandSeparator, thousandSeparator } from "../../shared/helpers/number-formatter";
import { GET_DASHBOARD_PAMM6_STAT } from "./Dashboard.gql";

const DashboardPAMM6Chart = () => {
    const { data } = useQuery(GET_DASHBOARD_PAMM6_STAT, { fetchPolicy: 'cache-and-network' });

    return (
        <>
            <div className="half-chart">
                <div>
                    <div>
                        <div className="chart-title">Total PAMM 6 Investors</div>
                        <div className="chart-value">{noneCurrencyThousandSeparator(data?.dashboardPAMM6Statistic?.count || 0)}</div>
                    </div>
                    <div>
                        <div className="chart-title">Total Fund</div>
                        <div className="chart-value">{thousandSeparator(data?.dashboardPAMM6Statistic?.amount || 0)}</div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="chart-title">Capital at MT5</div>
                        <div className="chart-value">{thousandSeparator(data?.dashboardPAMM6Statistic?.dynamicCapital || 0)}</div>
                    </div>
                    <div>
                        <div className="chart-title">Current Month Traded Lot</div>
                        <div className="chart-value">{noneCurrencyThousandSeparator(data?.dashboardPAMM6Statistic?.thisMonthVol || 0)}</div>
                    </div>
                </div>
                <div>PAMM 6 Statistic</div>
            </div>
        </>
    );
};
export default DashboardPAMM6Chart;