import React from "react";
import NoMatch from "./NoMatch";

export interface RouteInfo {
    path: string,
    component: any,
    routes?: RouteInfo[],
    lazy?: boolean,
    protected?: boolean
}

const ReactLazyPreload = (importStatement: any) => {
    const Component: any = React.lazy(importStatement);
    Component.preload = importStatement;
    return Component;
};

//preload pages
const ProfitSharingReport = ReactLazyPreload(() => import('./pages/reports/profit-sharing-report/ProfitSharingReport'));
ProfitSharingReport.preload();

const CommissionReports = ReactLazyPreload(() => import('./pages/reports/commission-reports/CommissionReports'));
CommissionReports.preload();

const GroupCommissionReports = ReactLazyPreload(() => import('./pages/reports/commission-reports/GroupCommissionReports'));
GroupCommissionReports.preload();

const UserHierarchy = ReactLazyPreload(() => import('./pages/members/hierarchy/MemberHierarchy'));
UserHierarchy.preload();

const MT4AccountTransactions = ReactLazyPreload(() => import('./pages/mt4-accounts/Mt4AccountsTransactions'));
MT4AccountTransactions.preload();

const MT4AccountTrade = ReactLazyPreload(() => import('./pages/mt4-accounts/Mt4AccountTrades'));
MT4AccountTrade.preload();

const MT4Accounts = ReactLazyPreload(() => import('./pages/mt4-accounts/Mt4Accounts'));
MT4Accounts.preload();

const TradeHistory = ReactLazyPreload(() => import('./pages/trade/TradeHistory'));
TradeHistory.preload();

const Withdrawals = ReactLazyPreload(() => import('./pages/withdrawals/Withdrawals'));
Withdrawals.preload();

const Mt4FundTransfers = ReactLazyPreload(() => import('./pages/mt4-fund-transfer-history/Mt4FundTransferHistory'));
Mt4FundTransfers.preload();

const ExpertAdvisorFollowers = ReactLazyPreload(() => import('./pages/expert-advisor-followers/ExpertAdvisorFollowers'));
ExpertAdvisorFollowers.preload();

const ExpertAdvisorForm = ReactLazyPreload(() => import('./pages/expert-advisor/ExpertAdvisorForm'));
ExpertAdvisorForm.preload();

const ExpertAdvisorTradeForm = ReactLazyPreload(() => import('./pages/expert-advisor/ExpertAdvisorTradeForm'));
ExpertAdvisorTradeForm.preload();

const ExpertAdvisor = ReactLazyPreload(() => import('./pages/expert-advisor/ExpertAdvisor'));
ExpertAdvisor.preload();

const ExpertAdvisorTradeHistory = ReactLazyPreload(() => import('./pages/expert-advisor/ExpertAdvisorTradeHistory'));
ExpertAdvisorTradeHistory.preload();

const RankForm = ReactLazyPreload(() => import('./pages/rank/RankForm'));
RankForm.preload();

const Rank = ReactLazyPreload(() => import('./pages/rank/Rank'));
Rank.preload();

const SideNavigationForm = ReactLazyPreload(() => import('./pages/side-navigation/SideNavigationForm'));
SideNavigationForm.preload();

const SideNavigation = ReactLazyPreload(() => import('./pages/side-navigation/SideNavigation'));
SideNavigation.preload();

const Kyc = ReactLazyPreload(() => import('./pages/kyc/Kyc'));
Kyc.preload();

const NewsForm = ReactLazyPreload(() => import('./pages/news/NewsForm'));
NewsForm.preload();

const News = ReactLazyPreload(() => import('./pages/news/News'));
News.preload();

const BannerForm = ReactLazyPreload(() => import('./pages/banner/BannerForm'));
BannerForm.preload();

const Banner = ReactLazyPreload(() => import('./pages/banner/Banner'));
Banner.preload();

const SystemUserForm = ReactLazyPreload(() => import('./pages/system-user/SystemUserForm'));
SystemUserForm.preload();

const SystemUser = ReactLazyPreload(() => import('./pages/system-user/SystemUser'));
SystemUser.preload();

const SystemConfiguration = ReactLazyPreload(() => import('./pages/system-configuration/SystemConfiguration'));
SystemConfiguration.preload();

const ChangePassword = ReactLazyPreload(() => import('./pages/change-password/ChangePassword'));
ChangePassword.preload();

const OTP = ReactLazyPreload(() => import('./pages/members/otp/OTP'));
OTP.preload();

const AccountWalletTransaction = ReactLazyPreload(() => import('./pages/members/accounts/AccountWalletTransaction'));
AccountWalletTransaction.preload();

const AccountForm = ReactLazyPreload(() => import('./pages/members/accounts/AccountForm'));
AccountForm.preload();

const AccountRankUpgrade = ReactLazyPreload(() => import('./pages/members/accounts/AccountRankUpgrade'));
AccountRankUpgrade.preload();

const Account = ReactLazyPreload(() => import('./pages/members/accounts/Account'));
Account.preload();

const Sponsor = ReactLazyPreload(() => import('./pages/members/sponsors/Sponsor'));
Sponsor.preload();

const ChangePasswords = ReactLazyPreload(() => import('./pages/settings/change-password/ChangePassword'));
ChangePasswords.preload();

const MyAccount = ReactLazyPreload(() => import('./pages/settings/my-account/MyAccount'));
MyAccount.preload();

const RankHistory = ReactLazyPreload(() => import('./pages/members/rank-history/RankHistory'));
RankHistory.preload();

const AuxiliaryPoints = ReactLazyPreload(() => import('./pages/members/auxiliary-points/AuxiliaryPoints'));
AuxiliaryPoints.preload();

const AuxiliaryPointsForm = ReactLazyPreload(() => import('./pages/members/auxiliary-points/AuxiliaryPointsForm'));
AuxiliaryPointsForm.preload();

const Competition = ReactLazyPreload(() => import('./pages/competition/competitions/Competition'));
Competition.preload();

const CompetitionForm = ReactLazyPreload(() => import('./pages/competition/competitions/CompetitionForm'));
CompetitionForm.preload();

const CompetitionParticipants = ReactLazyPreload(() => import('./pages/competition/competitions/CompetitionParticipants'));
CompetitionParticipants.preload();

const Mt4CompetitionTrades = ReactLazyPreload(() => import('./pages/competition/competitions/Mt4CompetitionTrades'));
Mt4CompetitionTrades.preload();

const CurrencyRateForm = ReactLazyPreload(() => import('./pages/currency-rates/CurrencyRateForm'));
CurrencyRateForm.preload();

const CurrencyRate = ReactLazyPreload(() => import('./pages/currency-rates/CurrencyRate'));
CurrencyRate.preload();

const DepositForm = ReactLazyPreload(() => import('./pages/deposits/DepositForm'));
DepositForm.preload();

const Deposit = ReactLazyPreload(() => import('./pages/deposits/Deposit'));
Deposit.preload();

const AdjustmentForm = ReactLazyPreload(() => import('./pages/adjustment/AdjustmentForm'));
AdjustmentForm.preload();

const Adjustment = ReactLazyPreload(() => import('./pages/adjustment/Adjustment'));
Adjustment.preload();

const LiteCompetitionFeeReport = ReactLazyPreload(() => import('./pages/reports/lite-competition-fee-report/LiteCompetitionFeeReport'));
LiteCompetitionFeeReport.preload();

const SalesReport = ReactLazyPreload(() => import('./pages/reports/sales-report/SalesReport'));
SalesReport.preload();

const PartnersReport = ReactLazyPreload(() => import('./pages/reports/partners-report/PartnersReport'));
PartnersReport.preload();

const PartnersReportForm = ReactLazyPreload(() => import('./pages/reports/partners-report/PartnersReportForm'));
PartnersReportForm.preload();

const PartnersReportEditForm = ReactLazyPreload(() => import('./pages/reports/partners-report/PartnersReportEditForm'));
PartnersReportEditForm.preload();

const PointAllocationForm = ReactLazyPreload(() => import('./pages/competition/point-allocation/PointAllocationForm'));
PointAllocationForm.preload();

const PointAllocation = ReactLazyPreload(() => import('./pages/competition/point-allocation/PointAllocation'));
PointAllocation.preload();

const Role = ReactLazyPreload(() => import('./pages/role/Role'));
Role.preload();

const RoleForm = ReactLazyPreload(() => import('./pages/role/RoleForm'));
RoleForm.preload();

const Announcements = ReactLazyPreload(() => import('./pages/announcements/Announcements'));
Announcements.preload();

const AnnouncementsForm = ReactLazyPreload(() => import('./pages/announcements/AnnouncementsForm'));
AnnouncementsForm.preload();

const CompetitionSeason = ReactLazyPreload(() => import('./pages/competition/competition-season/CompetitionSeason'));
CompetitionSeason.preload();

const CompetitionClosing = ReactLazyPreload(() => import('./pages/competition/competitions/CompetitionClosing'));
CompetitionClosing.preload();

const TrustAccount = ReactLazyPreload(() => import('./pages/trust-accounts/TrustAccounts'));
AuxiliaryPoints.preload();

const TrustAccountForm = ReactLazyPreload(() => import('./pages/trust-accounts/TrustAccountsForm'));
AuxiliaryPointsForm.preload();

const ExportLogs = ReactLazyPreload(() => import('./pages/export-log/ExportLog'));
ExportLogs.preload();

const WithdrawalsForm = ReactLazyPreload(() => import('./pages/withdrawals/WithdrawalsForm'));
WithdrawalsForm.preload();

const AddSponsor = ReactLazyPreload(() => import('./pages/members/sponsors/AddSponsor'));
AddSponsor.preload();

const ActionLogs = ReactLazyPreload(() => import('./pages/action-logs/ActionLogs'));
ActionLogs.preload();

const CorporateFund = ReactLazyPreload(() => import('./pages/corporate-group-fund/CorporateFund'));
CorporateFund.preload();

const AdminRemarksForm = ReactLazyPreload(() => import('./pages/withdrawals/AdminRemarksForm'));
AdminRemarksForm.preload();

const ThemeForm = ReactLazyPreload(() => import('./pages/theme/ThemeForm'));
ThemeForm.preload();

const Theme = ReactLazyPreload(() => import('./pages/theme/Theme'));
Theme.preload();

const HFundCommissionReports = ReactLazyPreload(() => import('./pages/reports/hfund-commission-reports/HFundCommissionReports'));
HFundCommissionReports.preload();

const HFundKYC = ReactLazyPreload(() => import('./pages/h-fund-kyc/HFundKYC'));
HFundKYC.preload();

const HFundWithdrawals = ReactLazyPreload(() => import('./pages/h-fund-withdrawals/HFundWithdrawals'));
HFundWithdrawals.preload();

const HFundDeposits = ReactLazyPreload(() => import('./pages/h-fund-deposits/HFundDeposits'));
HFundDeposits.preload();

const HFundDepositForm = ReactLazyPreload(() => import('./pages/h-fund-deposits/HFundDepositForm'));
HFundDepositForm.preload();

const HFundAdminRemarksForm = ReactLazyPreload(() => import('./pages/h-fund-withdrawals/HFundAdminRemarksForm'));
HFundAdminRemarksForm.preload();

const HFundWithdrawalsForm = ReactLazyPreload(() => import('./pages/h-fund-withdrawals/HFundWithdrawalsForm'));
HFundWithdrawalsForm.preload();

const MT4HFundTrades = ReactLazyPreload(() => import('./pages/mt4-hfund-trades/MT4HFundTrades'));
MT4HFundTrades.preload();

const Dashboard = ReactLazyPreload(() => import('./pages/dashboard/Dashboard'));
Dashboard.preload();

const CommissionSummaryReports = ReactLazyPreload(() => import('./pages/reports/commission-summary-reports/CommissionSummaryReports'));
CommissionSummaryReports.preload();

const EmailBroadcast = ReactLazyPreload(() => import('./pages/email-broadcast/EmailBroadcast'));
EmailBroadcast.preload();

const SendEmailBroadcast = ReactLazyPreload(() => import('./pages/email-broadcast/SendEmailBroadcast'));
SendEmailBroadcast.preload();

const PammRoi = ReactLazyPreload(() => import('./pages/pamm-roi/PammRoi'));
PammRoi.preload();

const WBTransaction = ReactLazyPreload(() => import('./pages/reports/wb-transaction-reports/WBTransaction'));
WBTransaction.preload();

export const routes: RouteInfo[] = [
    {
        path: "/login",
        component: React.lazy(() => import('./pages/login/Login'))
    }, {
        path: "/reports/profit-sharing-report",
        component: ProfitSharingReport,
        protected: true
    }, {
        path: "/reports/sales-report",
        component: SalesReport,
        protected: true
    }, {
        path: "/reports/level-commission-report",
        component: CommissionReports,
        protected: true
    }, {
        path: "/reports/rank-commission-report",
        component: CommissionReports,
        protected: true
    }, {
        path: "/reports/group-commission-report",
        component: GroupCommissionReports,
        protected: true
    }, {
        path: "/reports/hfund-commission-report",
        component: HFundCommissionReports,
        protected: true
    }, {
        path: "/reports/commission-summary-report",
        component: CommissionSummaryReports,
        protected: true
    }, {
        path: "/members/mt5-accounts/mt5-wallet-transactions/:id",
        component: MT4AccountTransactions,
        protected: true
    }, {
        path: "/members/mt5-accounts/mt5-wallet-trade/:id",
        component: MT4AccountTrade,
        protected: true
    }, {
        path: "/members/mt5-accounts",
        component: MT4Accounts,
        protected: true
    }, {
        path: "/member-activities/trade-history",
        component: TradeHistory,
        protected: true
    }, {
        path: "/member-activities/withdrawals/admin-remarks-edit/:id",
        component: AdminRemarksForm,
        protected: true
    }, {
        path: "/member-activities/withdrawals/withdrawals-edit/:id",
        component: WithdrawalsForm,
        protected: true
    }, {
        path: "/member-activities/withdrawals-approval",
        component: Withdrawals,
        protected: true
    }, {
        path: "/member-activities/withdrawals",
        component: Withdrawals,
        protected: true
    }, {
        path: "/member-activities/fund-transfers",
        component: Mt4FundTransfers,
        protected: true
    }, {
        path: "/copy-trade/expert-advisors/trades-history/:id",
        component: ExpertAdvisorTradeHistory,
        protected: true
    }, {
        path: "/copy-trade/expert-advisor-followers",
        component: ExpertAdvisorFollowers,
        protected: true
    }, {
        path: "/copy-trade/expert-advisors/edit-expert-advisor/:id",
        component: ExpertAdvisorForm,
        protected: true
    }, {
        path: "/copy-trade/expert-advisors/add-expert-advisor",
        component: ExpertAdvisorForm,
        protected: true
    }, {
        path: "/copy-trade/expert-advisors/edit-expert-advisor-trade/:id",
        component: ExpertAdvisorTradeForm,
        protected: true
    }, {
        path: "/copy-trade/expert-advisors/add-expert-advisor-trade",
        component: ExpertAdvisorTradeForm,
        protected: true
    }, {
        path: "/copy-trade/expert-advisors/edit-expert-advisor-trade/:id",
        component: ExpertAdvisorTradeForm,
        protected: true
    }, {
        path: "/copy-trade/expert-advisors",
        component: ExpertAdvisor,
        protected: true
    }, {
        path: "/admin/ranks/edit-rank/:id",
        component: RankForm,
        protected: true
    }, {
        path: "/admin/ranks/add-rank",
        component: RankForm,
        protected: true
    }, {
        path: "/admin/ranks",
        component: Rank,
        protected: true
    }, {
        path: "/admin/side-navigation/add-side-navigation",
        component: SideNavigationForm,
        protected: true
    }, {
        path: "/admin/side-navigation/edit-side-navigation/:id",
        component: SideNavigationForm,
        protected: true
    }, {
        path: "/admin/side-navigation",
        component: SideNavigation,
        protected: true
    }, {
        path: "/members/kyc",
        component: Kyc,
        protected: true
    }, {
        path: "/marketing/news/add-news",
        component: NewsForm,
        protected: true
    }, {
        path: "/marketing/news/edit-news/:id",
        component: NewsForm,
        protected: true
    }, {
        path: "/marketing/news",
        component: News,
        protected: true
    }, {
        path: "/marketing/banners/add-banner",
        component: BannerForm,
        protected: true
    }, {
        path: "/marketing/banners/edit-banner/:id",
        component: BannerForm,
        protected: true
    }, {
        path: "/marketing/banners",
        component: Banner,
        protected: true
    }, {
        path: "/marketing/themes/add-theme",
        component: ThemeForm,
        protected: true
    }, {
        path: "/marketing/themes/edit-theme/:id",
        component: ThemeForm,
        protected: true
    }, {
        path: "/marketing/themes",
        component: Theme,
        protected: true
    }, {
        path: "/marketing/email-broadcast/send-email",
        component: SendEmailBroadcast,
        protected: true
    }, {
        path: "/marketing/email-broadcast",
        component: EmailBroadcast,
        protected: true
    }, {
        path: "/admin/system-users/edit-system-user/:id",
        component: SystemUserForm,
        protected: true
    }, {
        path: "/admin/system-users/add-system-user",
        component: SystemUserForm,
        protected: true
    }, {
        path: "/admin/system-users",
        component: SystemUser,
        protected: true
    }, {
        path: "/admin/configuration",
        component: SystemConfiguration,
        protected: true
    }, {
        path: "/change-password",
        component: ChangePassword,
        protected: true
    }, {
        path: "/members/otp",
        component: OTP,
        protected: true
    }, {
        path: "/members/accounts/wallet-transaction/:id",
        component: AccountWalletTransaction,
        protected: true
    }, {
        path: "/members/sponsor/:id",
        component: Sponsor,
        protected: true
    }, {
        path: "/members/add-sponsor/:id",
        component: AddSponsor,
        protected: true
    }, {
        path: "/members/accounts/member-hierarchy/:id",
        component: UserHierarchy,
        protected: true
    }, {
        path: "/members/accounts/rank-upgrade/:id",
        component: AccountRankUpgrade,
        protected: true
    }, {
        path: "/members/accounts/edit-account/:id",
        component: AccountForm,
        protected: true
    }, {
        path: "/members/accounts/add-account",
        component: AccountForm,
        protected: true
    }, {
        path: "/members/accounts",
        component: Account,
        protected: true
    }, {
        path: "/members/accounts-verification",
        component: Account,
        protected: true
    }, {
        path: "/settings/change-password",
        component: ChangePasswords,
        protected: true
    }, {
        path: "/settings/my-account",
        component: MyAccount,
        protected: true
    }, {
        path: "/members/rank-history",
        component: RankHistory,
        protected: true
    }, {
        path: "/members/auxiliary-points/add-auxiliary-points",
        component: AuxiliaryPointsForm,
        protected: true
    }, {
        path: "/members/auxiliary-points",
        component: AuxiliaryPoints,
        protected: true
    }, {
        path: "/competition/competitions/mt5-competition-trades/:id",
        component: Mt4CompetitionTrades,
        protected: true
    }, {
        path: "/competition/trade-history",
        component: Mt4CompetitionTrades,
        protected: true
    }, {
        path: "/competition/competitions/competition-participants/:id",
        component: CompetitionParticipants,
        protected: true
    }, {
        path: "/competition/competitions/edit-competition/:id",
        component: CompetitionForm,
        protected: true
    }, {
        path: "/competition/competitions/add-competition",
        component: CompetitionForm,
        protected: true
    }, {
        path: "/competition/competitions",
        component: Competition,
        protected: true
    }, {
        path: "/competition/competition-season",
        component: CompetitionSeason,
        protected: true
    }, {
        path: "/competition/competition-closing",
        component: CompetitionClosing,
        protected: true
    }, {
        path: "/admin/currency-rates/add-currencyrate",
        component: CurrencyRateForm,
        protected: true
    }, {
        path: "/admin/currency-rates/edit-currencyrate/:id",
        component: CurrencyRateForm,
        protected: true
    }, {
        path: "/admin/currency-rates",
        component: CurrencyRate,
        protected: true
    }, {
        path: "/member-activities/deposits/edit-deposit/:id",
        component: DepositForm,
        protected: true
    }, {
        path: "/member-activities/deposits/add-deposit",
        component: DepositForm,
        protected: true
    }, {
        path: "/member-activities/deposits-approval",
        component: Deposit,
        protected: true
    }, {
        path: "/member-activities/deposits",
        component: Deposit,
        protected: true
    }, {
        path: "/member-activities/adjustment/deduct",
        component: AdjustmentForm,
        protected: true
    }, {
        path: "/member-activities/adjustment",
        component: Adjustment,
        protected: true
    }, {
        path: "/reports/lite-competition-fee-report",
        component: LiteCompetitionFeeReport,
        protected: true
    }, {
        path: "/reports/profit-sharing-report",
        component: ProfitSharingReport,
        protected: true
    }, {
        path: "/reports/partners-report/add",
        component: PartnersReportForm,
        protected: true
    }, {
        path: "/reports/partners-report/edit/:id",
        component: PartnersReportEditForm,
        protected: true
    }, {
        path: "/reports/partners-report",
        component: PartnersReport,
        protected: true
    }, {
        path: "/reports/wb-transaction",
        component: WBTransaction,
        protected: true
    }, {
        path: "/competition/point-allocation/add-point-allocation",
        component: PointAllocationForm,
        protected: true
    }, {
        path: "/competition/point-allocation",
        component: PointAllocation,
        protected: true
    }, {
        path: "/admin/role/edit-role/:id",
        component: RoleForm,
        protected: true
    }, {
        path: "/admin/role/add-role",
        component: RoleForm,
        protected: true
    }, {
        path: "/admin/roles",
        component: Role,
        protected: true
    }, {
        path: "/marketing/announcements/edit-announcement/:id",
        component: AnnouncementsForm,
        protected: true
    }, {
        path: "/marketing/announcements/add-announcement",
        component: AnnouncementsForm,
        protected: true
    }, {
        path: "/marketing/announcements",
        component: Announcements,
        protected: true
    }, {
        path: "/admin/trust-accounts/edit-trust-accounts/:id",
        component: TrustAccountForm,
        protected: true
    }, {
        path: "/admin/trust-accounts/add-trust-accounts",
        component: TrustAccountForm,
        protected: true
    }, {
        path: "/admin/trust-accounts",
        component: TrustAccount,
        protected: true
    }, {
        path: "/admin/export-logs",
        component: ExportLogs,
        protected: true
    }, {
        path: "/admin/action-logs",
        component: ActionLogs,
        protected: true
    }, {
        path: "/h-fund/kyc",
        component: HFundKYC,
        protected: true
    }, {
        path: "/h-fund/deposits/edit-deposit/:id",
        component: HFundDepositForm,
        protected: true
    }, {
        path: "/h-fund/deposits/add-deposit",
        component: HFundDepositForm,
        protected: true
    }, {
        path: "/h-fund/deposits-approval",
        component: HFundDeposits,
        protected: true
    }, {
        path: "/h-fund/deposits",
        component: HFundDeposits,
        protected: true
    }, {
        path: "/corporate-group-fund",
        component: CorporateFund,
        protected: true
    }, {
        path: "/pamm-roi",
        component: PammRoi,
        protected: true
    }, {
        path: "/h-fund/withdrawals/admin-remarks-edit/:id",
        component: HFundAdminRemarksForm,
        protected: true
    }, {
        path: "/h-fund/withdrawals/withdrawals-edit/:id",
        component: HFundWithdrawalsForm,
        protected: true
    }, {
        path: "/h-fund/withdrawals-approval",
        component: HFundWithdrawals,
        protected: true
    }, {
        path: "/h-fund/withdrawals",
        component: HFundWithdrawals,
        protected: true
    }, {
        path: "/h-fund/mt4-hfund-trades",
        component: MT4HFundTrades,
        protected: true
    }, {
        path: "/",
        component: Dashboard,
        protected: true
    }, {
        path: "*",
        component: NoMatch
    }
];