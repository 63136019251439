import './Dashboard.less';
import DashboardDepositCharts from "./DashboardDepositCharts";
import DashboardWithdrawalCharts from "./DashboardWithdrawalCharts";
import DashboardEATradeChart from "./DashboardEATradeChart";
import DashboardUserChart from "./DashboardUserChart";
import DashboardHedgeFundChart from "./DashboardHedgeFundChart";
import { GetRoleType } from '../../shared/helpers/general.helpers';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/auth/AuthContext';
import DashboardPAMM6TradeChart from './DashboardPAMM6TradeChart';
import DashboardPAMM6Chart from './DashboardPAMM6Chart';
import DashboardPAMM12Chart from './DashboardPAMM12Chart';

const Dashboard = () => {
  const { authState } = useAuth();
  const location = useLocation();
  const roleType = GetRoleType(location.pathname);
  const userName = authState.userProfile && authState.userProfile?.userName ? authState.userProfile?.userName : '';
  
  return (
    <>
      {roleType?.includes('R') ?
        <>
          <DashboardDepositCharts />
          <DashboardWithdrawalCharts />
          <DashboardUserChart />
          <DashboardEATradeChart />
          {/* <DashboardPAMM6TradeChart /> */}
          <div className="one-one-chart">
            <DashboardHedgeFundChart />
            <DashboardPAMM6Chart />
            <DashboardPAMM12Chart />
            <div className="half-chart"></div>
          </div>
        </>:
        <div style={{ textAlign: 'center' }}>
          <h1>Welcome, {userName}</h1>
        </div>
      }
    </>
  );
}

export default Dashboard;